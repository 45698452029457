@import "_grid.scss";
@import "_swiper.scss";

html {
  scroll-behavior:smooth;
  // overflow-x:hidden;
  width:100%;
    // background: #f2f2f2;
}
.caret {
  border-right: .05em solid;
  animation: caret 1s steps(1) infinite;
}

@keyframes caret {
  50% {
    border-color: transparent;
  }
}
body {
    background: #f9f9f9;
   overflow-x:hidden;
  width:100%;


}

img {
    width:100%;
    height:auto;
}
.backtotop {
  position: fixed;
  bottom:20px;
  right:-70px;
  width:40px;
  height:40px;
  border-radius:40px;
  background:rgba(orange,0.8);
  text-align:center;
  line-height:40px;
  svg {
    width:20px;
    height:20px;
    path { fill:#fff; }
  }
  &:hover {
    background:orange;
  }
}
.orange {
    color:orange;
}
.bigtitle {

    text-transform: uppercase;
    white-space: nowrap;
    color:#e5e5e5;;
    font-size:8em;
    // margin-left:10%;
    font-weight:700;
    position: absolute;
    // top:10px;
    width:100%;
    // text-align:right;
    z-index:0;
    @media only screen and (max-width: $breakpoint-med) {
        font-size:4em;
        text-align:left;
    }


}
header {
   

    .logo { 
        width:110px; 
        height:110px;
        border-radius:110px;
        border:2px solid orange;
     }
    .contact {

        margin-top:2em;
        svg {
            path { fill:orange; }
            height:1em;
            width:1em;
            vertical-align: middle;
            
        }
        .divider {
            margin-left:10px;
            margin-right:10px;
        }
    }
    // height:40vh;
        padding: 3em 0;
    background: linear-gradient(#fff 80%, #f0f0f0);
    text-align:center;

    .brand { width:100%; 

        // position:fixed;
        width:100%;

 

        @media only screen and (max-width: $breakpoint-med) {
  
            h1 {
              font-size: 1.5rem;
             
            }
            
            h3 {
              font-size: 1rem;
            } 
            
    
    }
}
    // h1,h2,h3,h4,h5 { margin: 0; }
}

.lax {
    transition: transform 0.9s cubic-bezier(.2,.89,.44,1);
   
}
main {
    width:100%;
    overflow:hidden;
    transition: 1s ease-out;
    opacity:0;
    &.tada { 

      opacity:1;
    }
  
}

section {
    // background: #f2f2f2;
       @media only screen and (max-width: $breakpoint-med) {
        //  min-height:55vh;
        //  align-items: top;
        display:block;
               margin-top:-50px;
    // padding-bottom:1em;

          .bigtitle { 
            font-size:4em;
            top:15px;
          }
       }

    max-width:960px;
    margin:0 auto;
    min-height:100vh;
    display: flex;
    align-items: center;
    overflow: visible;
    // overflow-x: hidden;
    padding-top:3em;
    padding-bottom:3em;
    .media {



        z-index:9;

        img { display:block; }
        
        box-shadow: 0 1px 1px rgba(0,0,0,0.06), 
              0 2px 2px rgba(0,0,0,0.06), 
              0 4px 4px rgba(0,0,0,0.06), 
              0 8px 8px rgba(0,0,0,0.06), 
              0 16px 16px rgba(0,0,0,0.06), 
              0 32px 32px rgba(0,0,0,0.06);

              @media only screen and (max-width: $breakpoint-med) {
             
               
            }

              
       
    }
   
    a.link { color:#777; text-decoration: none; border-bottom:1px dotted #777; transition: 0.5s ease-out;
        &:hover { color:orange; border-color:orange;}
    
    }
        $spacing: 2em;
        .title {
            // text-transform: uppercase;
            padding-top:$spacing;
            // background:rgba(orange,0.9); 
            
            h2,h4 { margin:0;
                color:orange;
              
            
            }
        
            @media only screen and (max-width: $breakpoint-med) {
                text-align: left;
                padding:1em;
            }
        }
        .body {
            padding-top:$spacing;
            // column-count: 2;
        //    .text { color:#fff; }
        //     background:#000;
            // background:rgba(#000,0.9);
            margin:0;
            @media only screen and (max-width: $breakpoint-med) {
                // column-count: 1;
                padding:1em;
            }
        }
        .tags { 
            font-size:0.90em; color:#777;  padding-top:$spacing;
             @media only screen and (max-width: $breakpoint-med) {
                text-align: left;
                padding:1em;
            }
        }
        .meta {
            span { text-transform: uppercase;  transition: 0.5s ease-out; }
            transition: 0.5s ease-out;
            padding:$spacing;
            background:#e5e5e5;
            // background:rgba(#e5e5e5,0.9);
            text-align: center;
            font-weight:700;
            margin:0;
            display:block;
            text-decoration: none;
            &:hover {
                background:#fff;
    
                color:orange;
                span {
                    color:orange;

                }
            }
            @media only screen and (max-width: $breakpoint-med) {
                padding:1em;
            }
        }
    

}

    svg {
        path { fill:orange; }
        height:1em;
        width:1em;
        vertical-align: middle;
        
    }
footer{
   
    margin:0 auto;
    padding-top:3em;
    padding-bottom:3em;
    background:#eee;
    text-align:center;
    h1,h2,h3,h4,h5,h6 { margin: 0;}
    small {
        font-size:0.6em;
    }
    .divider {
        margin-left:10px;
        margin-right:10px;
    }
}
/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: orange;
  } 
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: adjust-color(orange, $lightness: 20%);
  }
  .swiper-container {
      // background:#ddd;
      background: linear-gradient(#f0f0f0, #ddd);
  }
  .swiper-slide {
    height:100% !important;
  }
.loader {
    text-align: center;
    position: absolute;
    width: 100%;
    margin-top: 40px;
    opacity:1;
      transition: 1s ease-out;
}
.spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

}
.spinner div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: orange;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.spinner div:nth-child(1) {
  left: 8px;
  animation: spinner1 0.6s infinite;
}
.spinner div:nth-child(2) {
  left: 8px;
  animation: spinner2 0.6s infinite;
}
.spinner div:nth-child(3) {
  left: 32px;
  animation: spinner2 0.6s infinite;
}
.spinner div:nth-child(4) {
  left: 56px;
  animation: spinner3 0.6s infinite;
}
@keyframes spinner1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes spinner3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes spinner2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.video {
	position:relative;
	padding-bottom:56.25%;
	padding-top:30px;
	height:0;
  overflow:hidden;
  iframe  {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }
}
.typed-cursor {
  animation: typedjsBlink 0.7s infinite;
}